import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const SolaMunicipality: UserMunicipality = {
  name: 'sola',
  slogan: {value: '', id: 'banner.sola', meaning: 'bannerSlogan', description: 'slogan for sola'},
  data: {},
  bannerURL: 'https://ucarecdn.com/e658b789-364e-44e6-9d57-32d5b12a0356/Forside_Friskus_Sola.jpg',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: true,
  sunset: false,
  hasPayment: true,
  hasTickets: true,
  hasEquipment: false,
  infoPageTitle: 'Kom i gang',
  infoSlots: [
    {
      type: InfoType.FRISKUS_LEISURE_ALL,
      cardTitle: 'Kom i gang!',
      title: 'Fritidskonto',
      minAge: 8,
      maxAge: 14,
      amount: 1000,
      url: 'https://www.sola.kommune.no/solafritid/fritidskort-og-fritidskasse/aktivitetskortet/',
    },
    {
      type: InfoType.DYNAMIC,
      title: 'Tilskuddsordninger',
      content: 'Trenger din organisasjon støtte til spennende prosjekter og tiltak? Les mer om tilskudd det er mulig og søke på.',
      links: [
        {
          title: 'Tilskuddsordninger',
          url: 'https://www.sola.kommune.no/kultur-fritid-idrett-og-frivillighet/tilskudd/tilskudd/'
        },
      ],
    },
    {
      type: InfoType.DYNAMIC,
      title: 'Leie av utstyr',
      links: [
        {
          title: 'Leie av utstyr',
          url: 'https://frilager.no/ords/f?p=120:1'
        },
      ],
    },
    {
      type: InfoType.DYNAMIC,
      title: 'Fritidskontakt',
      content: 'Fritidskontakt er et gratis tilbud til alle som av ulike grunner knyttet til helse, funksjonsnedsetting eller sosiale utfordringer, har behov for en person å være sammen med på fritiden for å kunne ha en aktiv fritid og komme ut av en isolert tilværelse',
      links: [
        {
          title: 'Støttekontakt',
          url: 'https://www.sola.kommune.no/solafritid/tilrettelagt-fritid/fritidskontakttjenesten/vil-du-bli-fritidskontakt/'
        },
      ],
    },
    {
      type: InfoType.DYNAMIC,
      title: 'Frivillig innsats',
      content: 'Du kan engasjere deg som frivillig og gjøre en viktig innsats på mange forskjellige måter. Søk etter frivillige oppdrag i Aktivitetsoversikten eller besøk din Frivilligsentral.',
      links: [
        {
          title: 'Frivillig innsats',
          url: 'https://sola.friskus.com/events?filters=global_filters_municipalities(EQ)13a715d2-4c7a-4f6b-9fe0-1adc4cc2bfd2$$true(AND)events-filtersquick-filter-volunteer(EQ)true'
        },
      ],
    },
    {
      type: InfoType.DYNAMIC,
      title: 'Ungdom',
      content: 'Her har vi samlet noen tips til aktiviteter for ungdom. Finn flere aktiviteter i Aktivitetsoversikten og Arrangøroversikten ved å søke på Ungdom i filteret',
      links: [
        {
          title: 'Ungdom',
          url: 'https://sola.friskus.com/events?filters=global_filters_municipalities(EQ)13a715d2-4c7a-4f6b-9fe0-1adc4cc2bfd2$$true(AND)events-filtersage-group(EQ)teenager$$false'
        },
      ],
    },
    {
      type: InfoType.DYNAMIC,
      title: 'Senior',
      content: 'Her har vi samlet noen tips til aktiviteter for seniorer. Finn flere aktiviteter i Aktivitetsoversikten og Arrangøroversikten ved å søke på Senior i filteret',
      links: [
        {
          title: 'Senior',
          url: 'https://sola.friskus.com/events?filters=global_filters_municipalities(EQ)13a715d2-4c7a-4f6b-9fe0-1adc4cc2bfd2$$true(AND)events-filtersage-group(EQ)pensioner$$false'
        },
      ],
    },
    {
      type: InfoType.DYNAMIC,
      title: 'Lån av lokale',
      links: [
        {
          title: 'Lån av lokale',
          url: 'https://www.sola.kommune.no/utleie-av-kommunale-lokaler/'
        },
      ],
    },
    {
      type: InfoType.DYNAMIC,
      title: 'Frivilligsentraler',
      content: 'Frivilligsentralene er kontaktpunkt for mennesker, frivilligheten og kommunen i lokalmiljøet. Du finner kontaktinformasjon og alle åpne arrangement og frivillige oppdrag på frivilligsentralenes arrangørsider i Friskus.',
      links: [
        {
          title: 'Frivilligsentraler',
          url: 'https://sola.friskus.com/organisations/2c7ee030-6133-48bf-b44c-22b1097375d2'
        },
        {
          title: 'Frivilligsentraler',
          url: 'https://sola.frivilligsentral.no/'
        },
      ]
    },
  ]
}
