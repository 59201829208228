<form [formGroup]="searchForm">
  <mat-form-field *ngIf="!(isMobile | async)?.matches || !insideSidenav"
    class="w-100"
    appearance="outline">
    <mat-label i18n="Search events and voluntary label@@label.searchEventsVoluntary">Search events and voluntary assignments</mat-label>
    <input matInput
      [matAutocomplete]="auto"
      formControlName="search">
    <mat-icon matPrefix
      fontSet="material-icons-outlined"
      class="material-icons-round">
      search
    </mat-icon>
    <mat-icon matSuffix
      *ngIf="showClose"
      (click)="closed.emit(true)"
      fontSet="material-icons-outlined"
      class="material-icons-round pointer"
      matTooltip="Close"
      i18n-matTooltip="Close tooltip@@tooltip.close">Close</mat-icon>
  </mat-form-field>
  <div class="bg-primary-80 filled-input-container d-flex"
    *ngIf="(isMobile | async)?.matches && insideSidenav">
    <div class="d-flex flex-column justify-content-center">
      <mat-icon fontSet="material-icons-outlined"
      class="material-icons-round small-icon color-white">search</mat-icon>
    </div>
    <input matInput
      [placeholder]="placeholder"
      class="color-white pl-4 filled-input bg-primary-80"
      [matAutocomplete]="auto"
      formControlName="search">
    <div class="d-flex flex-column justify-content-center pr-4">
      <friskus-input-suffix [outline]="true"
      [controlName]="searchForm.controls['search']"></friskus-input-suffix>
    </div>
  </div>

  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="handleOptionSelect($event)"
    class="search-input">
    <mat-option *ngIf="isLoading">
      <div class="d-flex justify-content-center">
        <mat-spinner diameter="30"></mat-spinner>
      </div>
    </mat-option>

    <ng-container *ngIf="!isLoading">
      <div class="search-group d-flex py-2 px-3"
        *ngIf="eventsSearchItems.length">
        <h4 i18n="Events header@@label.events"
          class="text-uppercase font-weight-bold m-0">Events</h4>
      </div>
      <a class="text-decoration-none"
        *ngFor="let item of eventsSearchItems">
        <mat-option [value]="item.activity_id ? ['/events/dnt', item.activity_id] : ['/events', item.id]" 
                    (click)="handleItemChoose(item.activity_id ? ['/events/dnt', item.activity_id] : ['/events', item.id])"
          class="search-item">
          <span [innerHTML]="item.name"></span><br>
          <small [innerHTML]="item.description"></small>
        </mat-option>
      </a>

      <div class="py-2 px-3" *ngIf="eventsSearchItems.length >= 3">
        <a class="text-decoration-none search-item"
            (click)="handleItemChoose(['/search'], { queryParams: { q: searchForm.controls.search.value, tab: 'events' } })">
          <span i18n="Search results@@label.showMore">More results ...</span>
        </a>
      </div>

      <div class="search-group d-flex py-2 px-3"
        *ngIf="organizationSearchItems.length">
        <h4 i18n="Organizations header@@label.organizations"
          class="text-uppercase font-weight-bold m-0">Organizations</h4>

      </div>
      <a class="text-decoration-none"
        *ngFor="let item of organizationSearchItems">
        <mat-option [value]="['/organisations', item.id]" (click)="handleItemChoose(['/organisations', item.id])"
          class="search-item">
          <span [innerHTML]="item.name"></span><br>
          <small [innerHTML]="item.description"></small>
        </mat-option>
      </a>

      <div class="py-2 px-3" *ngIf="organizationSearchItems.length >= 3">
        <a class="text-decoration-none search-item"
            (click)="handleItemChoose(['/search'], { queryParams: { q: searchForm.controls.search.value, tab: 'organizations' } })">
          <span i18n="Search results@@label.showMore">More results ...</span>
        </a>
      </div>

      <mat-option
        *ngIf="!eventsSearchItems?.length && !organizationSearchItems?.length && !isFirstSearch  && searchForm.controls['search'].value?.length > 3"
        i18n="No results found label@@label.noResults">
        No results found
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</form>
