import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const NesoddenMunicipality: UserMunicipality = {
  name: 'nesodden',
  slogan: {
    value: '',
    id: 'banner.nesodden',
    meaning: 'bannerSlogan',
    description: 'slogan for nesodden'
  },
  data: {},
  bannerURL: 'https://ucarecdn.com/01d6a8f9-6ce5-45d6-9bb9-8052d43773f4/ungdomstilbudcolorrun.jpg',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: true,
  sunset: false,
  hasPayment: true,
  hasTickets: false,
  hasEquipment: false,
  infoPageTitle: 'Fritidskonto',
  infoSlots: [
    {
      type: InfoType.FRISKUS_LEISURE_CARD,
      title: 'Fritidskonto',
      minAge: 6,
      maxAge: 19,
      url: 'https://www.nesodden.kommune.no/aktuelt/na-kan-du-soke-om-aktivitetskort-for-2024.235840.aspx'
    },
    {
      type: InfoType.EQUIPMENT_RENTAL,
      title: 'BUA',
      name: 'BUA',
      url: 'https://www.nesodden.kommune.no/friskus/oversikt-over-arrangorer/?id=5b4291e1-904a-4518-9df3-92acf1abaedf'
    },
    {
      type: InfoType.VENUE,
      url: 'https://www.bookup.no/Utleie/#___/view:list/q:nesodden/mod:book',
    },
    {
      type: InfoType.FUNDING,
      url: 'https://www.nesodden.kommune.no/kultur-idrett-og-fritid/tilskuddsordninger/'
    },
    {
      type: InfoType.VOLUNTEER_CENTRE, url: ['/organisations', '9728bd43-873b-4e43-b8db-b347cc055d86']
    },
    {
      type: InfoType.OTHER,
      links: [
        {
          title: 'Aktivitetskort',
          url: 'https://www.nesodden.kommune.no/aktuelt/na-kan-du-soke-om-aktivitetskort-for-2024.235840.aspx',
        },
      ]
    },
    {
      type: InfoType.FOR_YOUTHS,
      links: [
        {
          url: 'https://www.rodekors.no/lokalforeninger/akershus/om/aktiviteter-i-distriktet/fellesverket/',
          title: "Fellesverket"
        },
        {
          url: 'https://nesodden.friskus.com/organisations/7774116a-b945-4251-8304-694a9618174f?tab=about',
          title: "Parlamentet"
        },
      ]
    },
    {
      type: InfoType.FOR_SENIORS,
      links: [
        {url: ['/organisations', '11493970-0e1f-443d-b77a-a242370f6190'], title: "Nesodden Seniornett"},
        {url: ['/organisations', 'a16ee70d-7efa-4a0a-bd9d-c7ee3509031b'], title: "Seniorkontakten"},
        {url: 'https://u3anesodden.no/', title: "U3A"},
      ]
    },
  ]
}
