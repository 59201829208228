<div class="calendar-custom-header d-flex flex-column justify-content-between pb-4">
  <div class="left">
    <mat-button-toggle-group (change)="eventsChange($event)"
      class="d-flex"
       *ngIf="user && !organizationId && !isTimePlan">
      <mat-button-toggle [value]="true"
        [checked]="isAllEventsView"
        class="flex-grow-1"
        i18n="All events buttons label@@button.allEvents">All events</mat-button-toggle>
      <mat-button-toggle [value]="false"
        [checked]="!isAllEventsView"
        class="flex-grow-1"
        *ngIf="user"
        i18n="Time plan buttons label@@button.timePlan">Time plan</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="center d-flex align-items-center">
    <button mat-button
      (click)="calendarPrev()"
      class="calendar-button p-0"
      aria-label="Go to previous Month">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <h2 class="calendar-title m-0 px-4">{{calendarTitle}}</h2>
    <button mat-button
      (click)="calendarNext()"
      class="calendar-button p-0"
      aria-label="Go to next Month">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</div>

<full-calendar #mobileCalendar
  [options]="calendarOptions">
</full-calendar>

<p class="m-0 text-label pt-2">
  <span i18n="Click date with to view details@@hint.clickDateToViewData">Click the date with activities to view detailed data</span>
</p>

<div *ngIf="occurrences.length && choosedDate"
  #occurrencesList>
  <p class="pt-3"><b i18n="Events on {chosed date} header label@@button.eventsOn">Events on {{choosedDate}}</b></p>
  <div class="bg-wrap-primary px-3 px-lg-5 py-4 mb-3"
    *ngFor="let occurrence of occurrences; let i = index;"
    [ngClass]="{'dnt-event': occurrence?.event_source === 'dnt'}">
    <div class="event-date-text mb-2 d-flex justify-content-between"
      *ngIf="occurrence">
      <span>
        {{occurrence.start_at | date : 'EEE, d MMM  y'}} &nbsp; &#183; &nbsp; {{occurrence.start_at | date : 'HH:mm'}}
        — {{occurrence.end_at | date : 'HH:mm'}}
      </span>
      <mat-icon class="event-actions-icon ml-auto pointer"
        [matMenuTriggerFor]="menu">more_vert</mat-icon>
      <mat-menu #menu="matMenu"
        xPosition="after"
        xPosition="after"
        overlapTrigger="true">
        <a [routerLink]="occurrence?.event_source === 'dnt' ? ['/events/dnt/', occurrence?.event?.id] : ['/events', occurrence?.event?.id]"
          mat-menu-item
          i18n="Check event details menuItem@@menu.eventDetails">Check event details</a>
        <!-- <a mat-menu-item
          (click)="leaveOccurrence(occurrence.id, i)"
          i18n="Cancel participation menuItem@@menu.cancelParticipation">Cancel participation</a> -->
      </mat-menu>
    </div>
    <div class="event-description-text my-2">
      <span>
        {{occurrence?.event?.name}}
      </span>
    </div>
    <div class="event-location-text mt-2">
      <span>
        {{occurrence?.event?.type?.name}}
        <ng-container *ngIf="occurrence.event.municipalities?.length">
          &nbsp; &#183; &nbsp;
          <span *ngFor="let municipality of occurrence.event.municipalities"> {{municipality.name}},</span>
        </ng-container>
      </span>
    </div>
  </div>
</div>

<div class="empty-list mt-4"
  *ngIf="!occurrences?.length">
  <div class="empty-hint">
    <h3 i18n="No events header@@noEventsHeader">No events</h3>
    <p i18n="No events calendar hint@@noEventsCalendarHint">No events founded for selected date</p>
  </div>
</div>
