import { PushNotificationsService } from './push-notifications.service';
import { API_URL_KEY, API_URL_MAP } from '@config/api-url.config';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericHttpService } from '@services/generic-http/generic-http.service';
import { Event, EventCategory, EventOccurrence } from '@shared/models/event.model';
import { MyAttendance } from '@shared/models/attendee.model';
import { shareReplay, tap } from 'rxjs/operators';
import {MunicipalitiesService} from "@services/remote-api/municipalities.service";
import {Params} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  private eventsUrl = API_URL_MAP.events;
  private typesRes$: Observable<any>;
  private categoriesRes$: Observable<any>;

  constructor(
    private http: GenericHttpService,
    private pushNotificationsService: PushNotificationsService,
    private municipalityService: MunicipalitiesService,
  ) { }

  public getEvents(params: any = {}, page: number = 1): Observable<any> {
    const url = `${this.eventsUrl.base}`;
    const searchParams = { ...params };
    searchParams.page = page;
    return this.http.get(API_URL_KEY.event, url, searchParams);
  }

  public getEventsOld(params: any = {}, page: number = 1): Observable<any> {
    const url = `${this.eventsUrl.old}`;
    const searchParams = { ...params };
    searchParams.page = page;
    return this.http.get(API_URL_KEY.event, url, searchParams);
  }

  public createEvent(eventData): Observable<any> {
    const url = `${this.eventsUrl.base}`;
    return this.http.post(API_URL_KEY.event, url, eventData).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public createDraft(eventData): Observable<any> {
    const url = `${this.eventsUrl.base}${this.eventsUrl.draft}`;
    return this.http.post(API_URL_KEY.event, url, eventData).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }


  public invite(eventId, data) {
    const url = `${this.eventsUrl.base}/${eventId}/invites`;
    return this.http.post(API_URL_KEY.event, url, data).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public cancelEventById(eventId) {
    const url = `${this.eventsUrl.base}/${eventId}${this.eventsUrl.cancel}`;
    return this.http.post(API_URL_KEY.event, url).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public uncancelEventById(eventId) {
    const url = `${this.eventsUrl.base}/${eventId}${this.eventsUrl.uncancel}`;
    return this.http.post(API_URL_KEY.event, url).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public markTaskAsCompleteById(eventId) {
    const url = `${this.eventsUrl.base}/${eventId}${this.eventsUrl.complete}`;
    return this.http.post(API_URL_KEY.event, url);
  }

  public unpublishEventDraftById(eventId) {
    const url = `${this.eventsUrl.base}/${eventId}${this.eventsUrl.unpublish}`;
    return this.http.post(API_URL_KEY.event, url);
  }

  public getEventById(eventId: string, fromFacebook?: boolean): Observable<{ data: Event }> {
    const params: Params = {};
    params.municipality = this.municipalityService.getUserMunicipality().name;
    if (fromFacebook) {
      params.from_facebook = true;
    }

    const url = `${this.eventsUrl.base}/${eventId}`;
    return this.http.get(API_URL_KEY.event, url, params, [["Accept-Language", this.getAppLang()]]);
  }

  public getEventsByGroupId(organizationId: string, page: number = 1, filter: any = {}, q?: string): Observable<any> {
    const url = `${this.eventsUrl.organizations}/${organizationId}${this.eventsUrl.base}`;
    return this.http.get(API_URL_KEY.event, url, { page, q, ...filter });
  }

  public getEventsListByGroupId(organizationId: string): Observable<any> {
    const url = `/${organizationId}/event-list`;
    return this.http.get(API_URL_KEY.event, url);
  }

  public updateEventById(eventId: string, eventData, notify: boolean = false): Observable<any> {
    const notifyQuery:string = notify ? '?notify=true' : '';
    const url = `${this.eventsUrl.base}/${eventId}${notifyQuery}`;
    return this.http.put(API_URL_KEY.event, url, eventData).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public publishEventById(eventId: string): Observable<any> {
    const url = `${this.eventsUrl.base}/${eventId}${this.eventsUrl.publish}`;
    return this.http.post(API_URL_KEY.event, url).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public updateDraftById(eventId: string, eventData): Observable<any> {
    const url = `${this.eventsUrl.base}${this.eventsUrl.draft}/${eventId}`;
    return this.http.put(API_URL_KEY.event, url, eventData);
  }

  public deleteEventById(eventId: string): Observable<any> {
    const url = `${this.eventsUrl.base}/${eventId}`;
    return this.http.del(API_URL_KEY.event, url).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public get eventTypes() {
    if (!this.typesRes$) {
      this.typesRes$ = this.getEventTypes().pipe(
        shareReplay()
      );
    }

    return this.typesRes$;
  }

  public getEventTypes(): Observable<any> {
    const url = `${this.eventsUrl.event}${this.eventsUrl.types}`;
    return this.http.get(API_URL_KEY.event, url);
  }

  public getEventMembersList(eventId: string, page: number = 1, search?: any): Observable<any> {
    const searchParams: any = {...search};
    searchParams.page = page;
    const url = `${this.eventsUrl.event}/${eventId}${API_URL_MAP.users.base}`;
    return this.http.get(API_URL_KEY.event, url, searchParams);
  }

  public getScheduledEventMembersList(eventId: string, page: number = 1, search?: any): Observable<any> {
    const searchParams: any = {...search};
    searchParams.page = page;
    const url = `${this.eventsUrl.event}/${eventId}/scheduled${API_URL_MAP.users.base}`;
    return this.http.get(API_URL_KEY.event, url, searchParams);
  }

  public updateAttendeesCount(eventId: string, count: number): Observable<any> {
    const url = `${this.eventsUrl.base}/${eventId}/attendees_count`;
    return this.http.put(API_URL_KEY.event, url, {count});
  }

  public getEventParticipantsCountByOccurrencest(eventId: string): Observable<any> {
    const url = `${this.eventsUrl.event}/${eventId}/users-count-by-occurrence`;
    return this.http.get(API_URL_KEY.event, url);
  }

  // Event comments

  public getEventComments(eventId: string): Observable<any> {
    const url = `${this.eventsUrl.event}/${eventId}${this.eventsUrl.comments}`;
    return this.http.get(API_URL_KEY.event, url);
  }

  public addCommentToEvent(eventId: string, commentData): Observable<any> {
    const url = `${this.eventsUrl.event}/${eventId}${this.eventsUrl.comments}`;
    return this.http.post(API_URL_KEY.event, url, commentData);
  }

  public deleteCommentFromEvent(eventId: string, commentId: string): Observable<any> {
    const url = `${this.eventsUrl.event}/${eventId}${this.eventsUrl.comments}/${commentId}`;
    return this.http.del(API_URL_KEY.event, url);
  }

  // Messages
  public sendMessageToEventAttendees(eventId: string, messageData: any) {
    const url = `${this.eventsUrl.event}/${eventId}${API_URL_MAP.messenger.messages}`;
    return this.http.post(API_URL_KEY.event, url, messageData);
  }

  // Event categories

  public get eventCategories() {
    if (!this.categoriesRes$) {
      this.categoriesRes$ = this.getCategories().pipe(
        shareReplay()
      );
    }

    return this.categoriesRes$;
  }

  public getCategories(): Observable<EventCategory[]> {
    const url = `${this.eventsUrl.categories}?type=events`;
    return this.http.get(
      API_URL_KEY.event,
      url,
      undefined,
      [[ "Accept-Language", this.getAppLang() ]]
    );
  }

  // Occurrences

  public attendEventOccurrence(occurenceId: string, data:{self_attending:boolean, guests:string[]}|null = null) {
    const url = `${this.eventsUrl.occurrences}/${occurenceId}${this.eventsUrl.attend}`;
    return this.http.post(API_URL_KEY.event, url, data ?? {}).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public leaveEventOccurrence(occurenceId: string) {
    const url = `${this.eventsUrl.occurrences}/${occurenceId}${this.eventsUrl.leave}`;
    return this.http.post(API_URL_KEY.event, url, {}).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public attendEventOccurrencesArray(eventId: string, occurensesData) {
    const url = `${this.eventsUrl.base}/${eventId}${this.eventsUrl.attend}`;
    return this.http.post(API_URL_KEY.event, url, occurensesData).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public getEventListWithOccurences(startDate, endDate, params?: any) {
    const searchParams = params || {};
    if (startDate) {
      searchParams.start_at = startDate;
    }

    if (endDate) {
      searchParams.end_at = endDate;
    }

    const url = `${this.eventsUrl.occurrences}${this.eventsUrl.collection}`;
    return this.http.get(API_URL_KEY.event, url, searchParams);
  }

  public getOccurencesList(eventId: string) {
    const url = `${this.eventsUrl.base}/${eventId}${this.eventsUrl.occurrences}`;
    return this.http.get(API_URL_KEY.event, url);
  }

  public publishOccurence(occurenceId: string) {
    const url = `${this.eventsUrl.occurrences}/${occurenceId}${this.eventsUrl.publish}`;
    return this.http.post(API_URL_KEY.event, url, {}).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public unpublishOccurence(occurenceId: string) {
    const url = `${this.eventsUrl.occurrences}/${occurenceId}${this.eventsUrl.unpublish}`;
    return this.http.post(API_URL_KEY.event, url, {}).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public updateOccurrence(occurenceId: string, occurenceData) {
    const url = `${this.eventsUrl.occurrences}/${occurenceId}`;
    return this.http.put(API_URL_KEY.event, url, occurenceData).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public getOccurrenceAttendees(occurenceId: string, page: number = 1, search?: any) {
    const searchParams: any = {...search};
    searchParams.page = page;
    searchParams.perPage = 30;
    const url = `${API_URL_MAP.events.occurrences}/${occurenceId}${API_URL_MAP.events.attendees}`;
    return this.http.get(API_URL_KEY.event, url, searchParams);
  }

  public assignMembersToOccurrence(occurenceId: string, membersData) {
    const url = `${API_URL_MAP.events.occurrences}/${occurenceId}${API_URL_MAP.events.attend}${API_URL_MAP.events.multiple}`;
    return this.http.post(API_URL_KEY.event, url, membersData).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public assignMembersToMultipleOccurrences(eventId: string, membersData) {
    const url = `${API_URL_MAP.events.base}/${eventId}${API_URL_MAP.events.joinAll}`;
    return this.http.post(API_URL_KEY.event, url, membersData).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public exportOccurrenceUsers(occurenceId: string, type: string = 'pdf') {
    const url = `${API_URL_MAP.events.occurrences}/${occurenceId}${API_URL_MAP.events.attendees}/download?type=${type}`;
    return this.http.getFile(API_URL_KEY.event, url);
  }

  public exportAllOccurrencesUsers(eventId: string, type: string = 'pdf') {
    const url = `${API_URL_MAP.events.base}/${eventId}${API_URL_MAP.events.attendees}/download?type=${type}`;
    return this.http.getFile(API_URL_KEY.event, url);
  }

  public removeAttendeeFromOccurrence(memberId: string) {
    const url = `${API_URL_MAP.events.attendees}/${memberId}`;
    return this.http.del(API_URL_KEY.event, url).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public moveAttendeeUpInList(memberId: string) {
    const url = `${API_URL_MAP.events.attendees}/${memberId}/up`;
    return this.http.post(API_URL_KEY.event, url, {});
  }

  public moveAttendeeDownInList(memberId: string) {
    const url = `${API_URL_MAP.events.attendees}/${memberId}/down`;
    return this.http.post(API_URL_KEY.event, url, {});
  }

  public getOrganizationMembersNotInOccurrence(occurenceId: string, page: number = 1) {
    const searchParams: any = {};
    searchParams.page = page;
    searchParams.perPage = 30;
    const url = `${API_URL_MAP.events.occurrences}/${occurenceId}/not${API_URL_MAP.events.attendees}`;
    return this.http.get(API_URL_KEY.event, url, searchParams);
  }

  // Slots

  public getAllEventSlots(eventId: string, dateParam?: string) {
    const searchParams: any = {};
    if (dateParam) {
      searchParams.date = dateParam;
    }
    const url = `${this.eventsUrl.base}/${eventId}${this.eventsUrl.slots}`;
    return this.http.get(API_URL_KEY.event, url, searchParams);
  }

  public getAllEventSlotsUsers(eventId: string, dateParam?: string, page: number = 1, search?: any) {
    const searchParams: any = {...search};
    if (dateParam) {
      searchParams.date = dateParam;
    }
    searchParams.page = page;
    const url = `${this.eventsUrl.base}/${eventId}${this.eventsUrl.slots}/users`;
    return this.http.get(API_URL_KEY.event, url, searchParams);
  }

  public exportSlotsUsers(eventId: string) {
    const url = `${this.eventsUrl.base}/${eventId}${this.eventsUrl.slots}/users/download`;
    return this.http.getFile(API_URL_KEY.event, url);
  }

  public getAllUserEventSlots(eventId: string) {
    const url = `${this.eventsUrl.base}/${eventId}${this.eventsUrl.slots}${this.eventsUrl.my}`;
    return this.http.get(API_URL_KEY.event, url);
  }

  public createSlot(eventId: string, slotData) {
    const url = `${this.eventsUrl.base}/${eventId}${this.eventsUrl.slots}`;
    return this.http.post(API_URL_KEY.event, url, slotData).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

    public getMyAttendanceTo(occurrenceId: string): Observable<{data: MyAttendance}> {
    const url = `${API_URL_MAP.events.occurrences}/${occurrenceId}/me`;
    return this.http.get(API_URL_KEY.event, url);
  }

  public updateSlot(eventId: string, slotId: string, slotData) {
    const url = `${this.eventsUrl.base}/${eventId}${this.eventsUrl.slots}/${slotId}`;
    return this.http.put(API_URL_KEY.event, url, slotData).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public deleteSlot(eventId: string, slotId: string) {
    const url = `${this.eventsUrl.base}/${eventId}${this.eventsUrl.slots}/${slotId}`;
    return this.http.del(API_URL_KEY.event, url).pipe(
      tap(() => this.pushNotificationsService.updateNotifications$.next(true))
    );
  }

  public downloadEvent(eventId: string) {
    const url = `${this.eventsUrl.base}/${eventId}/download`;
    return this.http.getFile(API_URL_KEY.event, url, {}, [["Accept-Language", this.getAppLang()]]);
  }

  public canAttend(event: Event) {
    return (event.can_sign_up || !event.is_canceled);
  }

  public isOccurrenceFull(event: Event, occurrence: EventOccurrence) {
    return event.capacity.max > 0 && occurrence.attendees_count >= event.capacity.max;
  }

  private getAppLang() {
    const route = window.location.pathname;
    if (route.includes(`/nn/`)) {
      return 'nn';
    }

    if (route.includes(`/en/`)) {
      return 'en';
    }

    return 'no';
  }

}
