<div mat-dialog-container>
  <div (click)="onCloseClick()"
    class="text-right close-icon">
    <mat-icon>close</mat-icon>
  </div>
  <ng-container [ngSwitch]="dialogData.type">
    <ng-container *ngSwitchCase="dialogType.DELETE_PHOTO">
      <h3 class="text-left mb-0 font-weight-bold"
        mat-dialog-title
        i18n="Delete header@@dialog.delete">Delete</h3>
      <div mat-dialog-content>
        <p class="text-left font-weight-normal mb-4"
          i18n="Are you sure you want to delete this photo paragraph@@dialog.areYouSure">
          Are you sure you want to delete this photo?
        </p>
      </div>

      <ng-container *ngTemplateOutlet="cancelDeleteButtons"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="dialogType.DELETE_DOCUMENT">
      <h3 class="text-left mb-0 font-weight-bold"
        mat-dialog-title
        i18n="Delete header@@dialog.delete">Delete</h3>
      <div mat-dialog-content>
        <p class="text-left font-weight-normal mb-4"
          i18n="Are you sure you want to delete this document paragraph@@dialog.areYouSureDeleteDocument">
          Are you sure you want to delete this document?
        </p>
      </div>

      <ng-container *ngTemplateOutlet="cancelDeleteButtons"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="dialogType.DELETE_ORGANIZATION">
      <h3 class="text-left mb-0 font-weight-bold"
        mat-dialog-title
        i18n="Delete header@@dialog.delete">Delete</h3>
      <div mat-dialog-content>
        <p class="text-left font-weight-normal mb-4"
          i18n="Are you sure you want to delete this organization paragraph@@dialog.areYouSureOrganization">
          Are you sure you want to delete organization? All data will be lost
        </p>
      </div>

      <ng-container *ngTemplateOutlet="cancelDeleteButtons"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="dialogType.ORGANIZATION_INVITATION">
      <h3 class="text-left mb-0 font-weight-bold"
        mat-dialog-title
        i18n="Invitation header@@dialog.invitation">Invitation</h3>
      <div mat-dialog-content>
        <p class="text-left font-weight-normal mb-4"
          i18n="You have been invited to join  paragraph@@dialog.organizationJoin">
          You have been invited to join '{{dialogData.organizationName}}' organization
        </p>
      </div>

      <ng-container *ngTemplateOutlet="declineAcceptButtons"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="dialogType.DELETE_EVENT">
      <h3 class="text-left mb-0 font-weight-bold"
        mat-dialog-title
        i18n="Delete header@@dialog.delete">Delete</h3>
      <div mat-dialog-content>
        <p class="text-left font-weight-normal mb-4"
          i18n="Are you sure you want to delete this event? paragraph@@dialog.areYouSureEvent">
          Are you sure you want to delete this event?
        </p>
      </div>

      <ng-container *ngTemplateOutlet="cancelDeleteButtons"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="dialogType.CANCEL_EVENT">
      <h3 class="text-left mb-0 font-weight-bold"
        mat-dialog-title
        i18n="Cancel header@@dialog.cancel">Cancel</h3>
      <div mat-dialog-content>
        <p class="text-left font-weight-normal mb-4"
          i18n=" Are you sure you want to cancel this event? paragraph@@dialog.areYouSureEventCancel">
          Are you sure you want to cancel this event?
        </p>
      </div>

      <ng-container *ngTemplateOutlet="declineAcceptButtons"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="dialogType.UNCANCEL_EVENT">
      <h3 class="text-left mb-0 font-weight-bold"
        mat-dialog-title
        i18n="Uncancel header@@dialog.uncancel">Uncancel</h3>
      <div mat-dialog-content>
        <p class="text-left font-weight-normal mb-4"
          i18n=" Are you sure you want to uncancel this event? paragraph@@dialog.areYouSureEventUncancel">
          Are you sure you want to uncancel this event?
        </p>
      </div>

      <ng-container *ngTemplateOutlet="declineAcceptButtons"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="dialogType.SAVE_EVENT_DRAFT">
      <h3 class="text-left mb-0 font-weight-bold"
        mat-dialog-title
        i18n="Draft header@@dialog.draft">Draft</h3>
      <div mat-dialog-content>
        <p class="text-left font-weight-normal mb-4"
          i18n="Are you sure you want to save this event as draft? paragraph@@dialog.areYouSureEventSaveAsDraft">
          Are you sure you want to save this event as draft?
        </p>
      </div>

      <ng-container *ngTemplateOutlet="declineAcceptButtons"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="dialogType.LEAVE_PAGE">
      <h3 class="text-left mb-0 font-weight-bold"
        mat-dialog-title
        i18n="Leave page header@@dialog.leave">Leave page?</h3>
      <div mat-dialog-content>
        <p class="text-left font-weight-normal mb-4"
          i18n="You will lose your unsaved changes paragraph@@dialog.leaveDescription">
          You will lose your unsaved changes if you navigate away without saving. Would you like to continue?
        </p>
      </div>
      <div mat-dialog-actions
        align="end"
        class="pb-4 pt-0">
        <button class="mr-2"
          mat-stroked-button
          color="primary"
          mat-dialog-close
          i18n="Cancel button label@@button.cancel">
          CANCEL
        </button>
        <button mat-raised-button
          color="primary"
          (click)="onConfirmClick()"
          i18n="Leave button label@@button.leave">Leave</button>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="dialogType.LEAVE_EVENT">
      <h3 class="text-left mb-0 font-weight-bold"
        mat-dialog-title
        i18n="Leave event header@@dialog.leaveEvent">Leave event occurence?</h3>
      <div mat-dialog-content>
        <p class="text-left font-weight-normal mb-4"
          i18n="You sure you want to leave this event? paragraph@@dialog.wantLeave">
          You sure you want to leave this event?
        </p>
      </div>
      <ng-container *ngTemplateOutlet="cancelLeaveButtons"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="dialogType.LEAVE_ORGANIZATION">
      <h3 class="text-left mb-0 font-weight-bold"
        mat-dialog-title
        i18n="Cancellation membership header@@dialog.cancellationMembership">Cancellation membership</h3>
      <div mat-dialog-content>
        <p class="text-left font-weight-normal mb-4"
          i18n="Are you sure you want to cancel your membership on paragraph@@dialog.wantLeaveOrganization">
          Are you sure you want to cancel your membership on <b>{{dialogData.organization.name}}</b> organization?
        </p>
      </div>
      <ng-container *ngTemplateOutlet="cancelLeaveButtons"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="dialogType.CANCEL_ORGANIZATION_REQUEST">
      <h3 class="text-left mb-0 font-weight-bold"
        mat-dialog-title
        i18n="Decline request header@@dialog.decline">Decline organization request?</h3>
      <div mat-dialog-content>
        <p class="text-left font-weight-normal mb-4"
          i18n="We will notify an applicant that request was declined@@dialog.weWillNotify">
          We will notify an applicant that request was declined.
        </p>
      </div>
      <div mat-dialog-actions
        align="end"
        class="pb-4 pt-0">
        <button class="mr-2"
          mat-stroked-button
          color="primary"
          mat-dialog-close
          i18n="Cancel button label@@button.cancel"> CANCEL </button>
        <button mat-raised-button
          color="primary"
          (click)="onConfirmClick()"
          i18n="Decline button label@@button.decline">Decline</button>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="dialogType.DELETE_THREAD">
      <h3 class="text-left mb-0 font-weight-bold"
        mat-dialog-title
        i18n="Delete chat header@@dialog.deleteChat">Delete chat</h3>
      <div mat-dialog-content>
        <p class="text-left font-weight-normal mb-4"
          i18n="Are you sure you want to delete and leave the chat paragraph@@dialog.wantDeleteChat">
          Are you sure you want to delete and leave the <b>{{dialogData.chatName}}</b> chat?
        </p>
      </div>
      <ng-container *ngTemplateOutlet="cancelDeleteButtons"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="dialogType.DELETE_CERTIFICATE">
      <h3 class="text-left mb-0 font-weight-bold"
        mat-dialog-title
        i18n="Delete chat header@@dialog.deleteChat">Delete chat</h3>
      <div mat-dialog-content>
        <p class="text-left font-weight-normal mb-4"
          i18n="Are you sure you want to delete the certificate paragraph@@dialog.wantDeleteCertificate">
          Are you sure you want to delete the certificate?
        </p>
      </div>
      <ng-container *ngTemplateOutlet="cancelDeleteButtons"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="dialogType.DELETE_POST">
      <h3 class="text-left mb-0 font-weight-bold"
        mat-dialog-title
        i18n="Delete post header@@dialog.deletePost">Delete post</h3>
      <div mat-dialog-content>
        <p class="text-left font-weight-normal mb-4"
          i18n="Are you sure you want to delete the post paragraph@@dialog.wantDelete{Post}">
          Are you sure you want to delete the post?
        </p>
      </div>
      <ng-container *ngTemplateOutlet="cancelDeleteButtons"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="dialogType.CHANGE_EVENT_PERIOD">
      <h3 class="text-left mb-0 font-weight-bold"
        mat-dialog-title
        i18n="Change period header@@dialog.changePeriod">Change period?</h3>
      <div mat-dialog-content>
        <p class="text-left font-weight-normal mb-4"
          i18n="Change event period hint paragraph@@dialog.changePeriodNotify">
          This changes lead to canceling existing membership participant on events. All participants will be notify.
        </p>
      </div>
      <div mat-dialog-actions
        align="end"
        class="pb-4 pt-0">
        <button class="mr-2"
          mat-stroked-button
          color="primary"
          mat-dialog-close
          i18n="Cancel button label@@button.cancel"> CANCEL </button>
        <button mat-raised-button
          color="primary"
          (click)="onConfirmClick()"
          i18n="Continue button label@@button.continue">Continue</button>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="dialogType.REMOVE_CULTURE_CARD">
      <h3 class="text-left mb-0 font-weight-bold"
        mat-dialog-title
        i18n="Remove culture card header@@dialog.removeCulture`card">Remove culture card</h3>
      <div mat-dialog-content>
        <p class="text-left font-weight-normal mb-4"
          i18n="Are you sure you want to remove the culture card paragraph@@dialog.wantDeleteCultureCard">
          Are you sure you want to remove the culture card?
        </p>
      </div>
      <ng-container *ngTemplateOutlet="cancelDeleteButtons"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="dialogType.REMOVE_PARTICIPANT">
      <h3 class="text-left mb-0 font-weight-bold"
        mat-dialog-title
        i18n="Remove participant header@@dialog.removeParticipant">Remove participant</h3>
      <div mat-dialog-content>
        <p class="text-left font-weight-normal mb-4"
          i18n="Are you sure you want to remove this participant from event paragraph@@dialog.wantRemoveParticipant">
          Are you sure you want to remove this participant from event?
        </p>
      </div>
      <ng-container *ngTemplateOutlet="cancelDeleteButtons"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="dialogType.DELETE_USER">
      <h3 class="text-left mb-0 font-weight-bold"
        mat-dialog-title
        i18n="Delete user header@@dialog.deleteUser">Delete user</h3>
      <div mat-dialog-content>
        <p class="text-left font-weight-normal mb-4"
          i18n="Are you sure you want to remove this user paragraph@@dialog.wantRemoveUser">
          Are you sure you want to remove this user?
        </p>
      </div>
      <ng-container *ngTemplateOutlet="cancelDeleteButtons"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="dialogType.CONDITIONS_ACCEPT">
      <h3 class="text-left mb-0 font-weight-bold"
        mat-dialog-title
        i18n="Confirmation header@@dialog.confirmation">Confirmation</h3>
      <div mat-dialog-content class="d-flex flex-column gap-2 mb-4">
        <p i18n="By continuing, you are accept Friskus terms of use paragraph@@dialog.onContinueYouAcceptTerms" class="text-left font-weight-normal mb-4">
          By continuing, you accept Friskus's terms of use
        </p>
        
        <a (click)="openPdf('privacyPolicy')"
        target="_blank"
        class="link word-break-normal"
        i18n="Confirmation terms of use link@@dialog.privacyPolicy">Privacy Policy</a>
        <a (click)="openPdf('dpa')"
        target="_blank"
        class="link word-break-normal"
        i18n="Confirmation terms of use link@@dialog.dataProcessingAgreement">Data Processing Agreement</a>
      </div>

      <ng-container *ngTemplateOutlet="declineAcceptButtons"></ng-container>
    </ng-container>


    <ng-container *ngSwitchCase="dialogType.IFRAME_PRIVACY_POLICY_PDF">
      <div class="pdf-container">
        <iframe title="privacy policy" src="https://friskusas.no/wp-content/uploads/2024/11/2024-10-10_personvernerklaering-friskus.pdf"
          class="pdf-iframe"
          aria-label="PDF privacy policy document"
          width="100%" height="800px">
        </iframe>
        <p i18n="Iframe policy fallback message for privacy policy@@dialog.policy.privacyPolicyMessage"> Don't have a PDF plugin for this browser ?.
          No biggie... you can <a target="_blank" href="https://friskusas.no/wp-content/uploads/2024/11/2024-10-10_personvernerklaering-friskus.pdf">click here to
          view the PDF file.</a>
        </p>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="dialogType.IFRAME_DPA_PDF">
      <div class="pdf-container">
        <iframe title="Data processing agreement" src="https://friskusas.no/wp-content/uploads/2024/11/2024-06-19_databehandleravtale-arrangorer.pdf"
          class="pdf-iframe"
          aria-label="PDF data processing agreement document"
          width="100%" height="800px">
        </iframe>
        <p i18n="Iframe policy fallback message for DPA@@dialog.policy.dpaMessage" >Don't have a PDF plugin for this browser ?.
          No biggie... you can <a target="_blank" href="https://friskusas.no/wp-content/uploads/2024/11/2024-06-19_databehandleravtale-arrangorer.pdf">click here to
          view the PDF file.</a>
        </p>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="dialogType.NOTIFY_USERS">
      <h3 class="text-left mb-0 font-weight-bold"
        mat-dialog-title
        i18n="Notification dialog header@@dialog.notification">Notify users?</h3>
      <div mat-dialog-content>
        <p class="text-left font-weight-normal mb-4">
          <span  i18n="Should users be notified?@@dialog.notifyUsers">Should users be notified about change?</span>
        </p>
      </div>

      <ng-container *ngTemplateOutlet="yesNoButtons"></ng-container>
    </ng-container>



    <ng-container *ngSwitchCase="dialogType.MARK_AS_COMPLETE">
      <h3 class="text-left mb-0 font-weight-bold"
        mat-dialog-title
        i18n="Confirmation header@@dialog.confirmation">Confirmation</h3>

      <div mat-dialog-content>
        <p class="text-left font-weight-normal mb-4"
          i18n=" Are you sure you want to mark this task as complete paragraph@@dialog.areYouSureTaskAsComplete">
          Are you sure you want to mark this task as complete
        </p>
      </div>

      <ng-container *ngTemplateOutlet="declineAcceptButtons"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="dialogType.DELETE_COMMENT">
      <h3 class="text-left mb-0 font-weight-bold"
          mat-dialog-title
          i18n="Delete header@@dialog.delete">Delete</h3>
      <div mat-dialog-content>
        <p class="text-left font-weight-normal mb-4"
           i18n="Are you sure you want to delete this comment ? paragraph@@dialog.areYouSureDeleteComment">
          Are you sure you want to delete this comment ?
        </p>
      </div>

      <ng-container *ngTemplateOutlet="cancelDeleteButtons"></ng-container>
    </ng-container>
  </ng-container>

  <ng-template #cancelDeleteButtons>
    <div mat-dialog-actions
      align="end"
      class="pb-4 pt-0">
      <button class="mr-2"
        mat-stroked-button
        color="primary"
        mat-dialog-close
        i18n="Cancel button label@@button.cancel">
        CANCEL
      </button>
      <button mat-raised-button
        color="primary"
        (click)="onConfirmClick()"
        i18n="Delete button label@@button.deleteImage">
        DELETE
      </button>
    </div>
  </ng-template>
  <ng-template #declineAcceptButtons>
    <div mat-dialog-actions
      align="end"
      class="pb-4 pt-0">
      <button class="mr-2"
        mat-stroked-button
        color="primary"
        mat-dialog-close
        i18n="Decline button label@@button.decline">Decline</button>
      <button mat-raised-button
        color="primary"
        (click)="onConfirmClick()"
        i18n="Accept button label@@button.accept">Accept</button>
    </div>
  </ng-template>

  <ng-template #yesNoButtons>
    <div mat-dialog-actions
         align="end"
         class="pb-4 pt-0">
      <button mat-stroked-button
              class="mr-2"
              color="primary"
              (click)="onConfirmClick()"
              i18n="Yes button label@@button.yes">Yes</button>
      <button mat-raised-button
              color="primary"
              (click)="onCloseClick()"
              i18n="No button label@@button.no">No</button>
    </div>
  </ng-template>

  <ng-template #cancelLeaveButtons>
    <div mat-dialog-actions
      align="end"
      class="pb-4 pt-0">
      <button class="mr-2"
        mat-stroked-button
        color="primary"
        mat-dialog-close
        i18n="Cancel button label@@button.cancel"> CANCEL </button>
      <button mat-raised-button
        color="primary"
        (click)="onConfirmClick()"
        i18n="Leave button label@@button.leaveParticipation">Leave</button>
    </div>
  </ng-template>

</div>
