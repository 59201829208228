<div class="navbar bg-primary py-0 px-6">
  <a [routerLink]="['/']"
    class="mr-4">
    <img class="img-fluid header-logo"
      src="../../assets/img/logo_friskus.svg"
      alt="Logo">
  </a>

  <div class="ml-auto d-flex align-items-center">
    <!-- <img [src]="organization.logo_url" alt="Logo" class="org-logo mr-2"> -->
    <h1 class="text-white m-0" *ngIf="organization">{{organization.name}}</h1>
    <h1 class="text-white m-0" *ngIf="municipalitiesNameFilter.length">
      <span *ngFor="let municipality of municipalitiesNameFilter; let i = index;">
        &nbsp;{{municipality.name}}
        <ng-container *ngIf="i < municipalitiesNameFilter.length - 1">&nbsp;&#183;</ng-container>
      </span>
    </h1>
  </div>

  <div class="ml-auto"
    *ngIf="meta">
    <p class="text-white m-0">{{page}}/{{meta.last_page}}</p>
  </div>
</div>

<div class="event-container"
  [style]="eventStyle()"
  [@listAnimation]="events.length">
  <friskus-event-card *ngFor="let event of events"
    [event]="event"
    [routerLink]="event.event_source === 'dnt' ? ['/events/dnt/', event.id] : ['/events', event.id]"
    class="event-item">
  </friskus-event-card>
</div>
