import {InfoType, UserMunicipality} from '@shared/models/user-municipality.model';
  
// custom content variables
const customAge = '1. -10. klasse';

export const SandnesMunicipality: UserMunicipality = {
  name: 'sandnes',
  slogan: {
    value: 'Sandnes kommune',
  },
  data: {},
  bannerURL: 'https://ucarecdn.com/f69ebf5d-7d8b-4b8b-b745-f2bf3df47ac6/Vinterland1450.jpg',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: true,
  hasTickets: true,
  hasEquipment: false,
  infoPageTitle: "Sandneskortet",
  infoSlots: [
    {
      type: InfoType.DYNAMIC,
      title: 'Gratisbilletter',
      content: `Sandneskortet støtter gratisbilletter til utvalgte kultur og fritidsaktiviteter til barn og unge fra ${customAge}.
      Les mer om hvordan få tilgang eller kom i gang med gratisbilletter.`,
      links: [
        {
          title: 'Les mer',
          url: 'https://www.sandnes.kommune.no/sandneskortet/',
        },
      ],
    },
    {
      type: InfoType.FRISKUS_LEISURE_ALL,
      amount: 500,
      minAge: 6,
      maxAge: 16,
      url: 'https://www.sandnes.kommune.no/sti/kultur/fritidstilbud/sandneskortet/',
    },
  ]
}
