import { InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const KinnMunicipality: UserMunicipality = {
  name: 'kinn',
  slogan: {
    value: 'Aktiviteter og frivillige oppdrag - nær deg'
  },
  data: {},
  bannerURL: 'https://ucarecdn.com/2ba87b6b-3300-434c-97db-0744555242bb/-/preview/2000x600/',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: true,
  sunset: false,
  hasPayment: true,
  hasTickets: false,
  hasEquipment: false,
  infoPageTitle: "Fritidskonto",
  infoSlots: [
    {
      type: InfoType.FRISKUS_LEISURE_CARD,
      title: 'Fritidskonto',
      minAge: 6,
      maxAge: 18,
      url: 'https://kinn.kommune.no/vare-tenester/kultur-idrett-og-fritid/tilskotsordningar/okonomisk-stotte-til-fritidsaktivitet/',
    },
  ]
}
