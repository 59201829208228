import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const GrongDevMunicipality : UserMunicipality = {
    name: 'grong-dev',
    slogan: {value: 'Finn frem til aktivitet og frivillighet <br> - reklamefritt og gratis', id: 'banner.grong', meaning: 'bannerSlogan', description: 'slogan for Grong'},
    data: {},
    bannerURL: 'https://ucarecdn.com/2ba87b6b-3300-434c-97db-0744555242bb/-/preview/2000x600/',
    bannerType: 'image',
    hasLeisureCard: false,
    userCanClaimLeisureCard: false,
    canClaimInOrganization: false,
    hasSubsidiaryOrg: false,
    sunset: false,
    hasPayment: false,
    hasTickets: false,
    hasEquipment: false,
    infoPageTitle: 'Fritidskonto',
  }
  