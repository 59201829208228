import {UserMunicipality} from "@shared/models/user-municipality.model";

export const karmoyMunicipality: UserMunicipality = {
  name: 'karmoy',
  slogan: {value: 'Aktiviteter og frivillige oppdrag - nær deg', id: 'banner.karmoy', meaning: 'bannerSlogan', description: 'slogan for karmoy'},
  data: {
    headerDescription: 'headerDescription',
  },
  bannerURL: 'https://ucarecdn.com/2ba87b6b-3300-434c-97db-0744555242bb/-/preview/2000x600/',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: true,
  hasEquipment: false,
}
