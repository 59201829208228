import { InfoType, UserMunicipality} from '@shared/models/user-municipality.model';

export const NoreoguvdalMunicipality: UserMunicipality = {
  name: 'noreoguvdal',
  slogan: {
    value: 'Oppdag aktiviteter og <br> organisasjoner i nore og uvdal',
    id: 'banner.noreoguvdal',
    meaning: 'bannerSlogan',
    description: 'slogan for noreoguvdal'
  },
  data: {},
  bannerURL: 'https://ucarecdn.com/fbade480-84b0-409d-88f7-a1a4c66c795b/-/quality/smart/-/format/auto/',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: true,
  hasTickets: true,
  hasEquipment: false,
  infoPageTitle: 'Fritidskonto',
  infoSlots: [
    {
      type: InfoType.FRISKUS_LEISURE_CARD,
      title: 'Fritidskortet',
      minAge: 6,
      maxAge: 24,
      url: 'https://www.nore-og-uvdal.kommune.no/nyhet/gladmelding-fra-nore-og-uvdal-kommune',
    }
  ],
}
