import {UserMunicipality} from '@shared/models/user-municipality.model';

export const BoknMunicipality: UserMunicipality = {
  name: 'bokn',
  slogan: {
    value: 'Aktiviteter og frivillige oppdrag - nær deg'
  },
  data: {},
  bannerURL: 'https://ucarecdn.com/886f31b7-cda1-4765-9e4e-038d609e7127/SolnedgangpanoramaBoknasundet.jpg',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
  hasEquipment: false,
  infoPageTitle: "Fritidskonto"
}
