import { AuthService } from '@auth/shared/services/auth/auth.service';
import { UsersService } from '@shared/services/remote-api/users.service';
import { dateFormat } from '@config/index';
import { EventsService } from '@services/remote-api/events.service';
import {
  Component,
  OnInit,
  ViewChild,
  AfterViewChecked,
  ChangeDetectorRef,
  Input,
  SimpleChanges,
  OnChanges,
  Inject,
  LOCALE_ID,
  Output,
  ElementRef
} from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { CalendarOptions, EventInput } from '@fullcalendar/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatMenuTrigger } from '@angular/material/menu';
import * as moment from 'moment';
import { FullCalendarComponent, ViewApi } from '@fullcalendar/angular';
import { Observable } from 'rxjs';
import { BreakpointState } from '@angular/cdk/layout';
import { ScreenTypeService } from '@shared/services/screen-type.service';
import { EventCalendarService } from './event-calendar.service';
import nnLocale from '@fullcalendar/core/locales/nn';
import nbLocale from '@fullcalendar/core/locales/nb';
import { EventEmitter } from '@angular/core';
import { take } from 'rxjs/operators';
import { Router } from "@angular/router";

@Component({
  selector: 'friskus-event-calendar',
  templateUrl: './event-calendar.component.html',
  styleUrls: ['./event-calendar.component.scss']
})
export class EventCalendarComponent implements OnInit, AfterViewChecked, OnChanges {
  @Input() filterParams: any = {};
  @Input() organizationId: string;
  @Input() isTimePlan: boolean;
  @Output() loading: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatMenuTrigger, { static: true }) trigger: MatMenuTrigger;
  @ViewChild('popupBtn', { static: true, read: ElementRef }) popupBtn: ElementRef;
  @ViewChild('calendar') calendar: FullCalendarComponent;

  public clickedEvent: EventInput;
  public events: any[];
  public timeFormat = {};
  public isLoading: boolean;
  private lastCurrentDates = {
    startDay: moment().startOf('month').format(dateFormat.short),
    endDay: moment().endOf('month').format(dateFormat.short)
  };
  public allEventsMode = true;
  public calendarTitle: string;
  public isCalendarInit: boolean;
  public isMobile: Observable<BreakpointState>;
  public user: any;
  public isOnWaitingList = false;
  public calendarLocale;
  public calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    dayHeaders: true,
    fixedWeekCount: false,
    showNonCurrentDates: false,
    displayEventEnd: true,
    height: 'auto',
    contentHeight: 'auto',
    firstDay: 1,
    // eventLimit: 10,
    timeZone: 'local',
    eventMaxStack: 10,
    dayMaxEvents: 10,
    dayMaxEventRows: 10,
    eventClick: this.onEventClick.bind(this),
    // viewDidMount: this.datesRender.bind(this),
    datesSet: this.datesRender.bind(this),
    views: {
      timeGrid: {
        dayMaxEventRows: 10
      }
    },
  };

  private nextPreviousButtons: { next: string, previous: string } = { previous:'Go to previous Month', next: 'Go to next Month' };

  constructor(
    private eventsService: EventsService,
    private usersService: UsersService,
    private cdRef: ChangeDetectorRef,
    private screenType: ScreenTypeService,
    private calendarService: EventCalendarService,
    private authService: AuthService,
    private router: Router,
    @Inject(LOCALE_ID) public locale: string
  ) {
    this.isMobile = this.screenType.isHandset;
    this.timeFormat = this.calendarService.timeFormat;
  }

  ngOnInit() {
    this.authService.authState.subscribe(user => this.user = user);
    this.setCalendarLocale(this.locale);
    this.calendarOptions.eventTimeFormat = this.timeFormat;
    this.calendarOptions.slotLabelFormat = this.timeFormat;
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.isMobile
      .pipe(take(1))
      .subscribe(res => {
        if (this.lastCurrentDates.startDay && this.lastCurrentDates.endDay && !res.matches) {
          if (this.isTimePlan) {
            this.allEventsMode = false;
          }
          this.getEvents(this.filterParams);
        }
      });
  }

  public viewChange(event: MatButtonToggleChange) {
    this.calendar.getApi().changeView(event.value);
    if(event)
    switch (event.value) {
      case 'dayGridMonth':
        this.nextPreviousButtons = { previous:'Go to previous Month', next: 'Go to next Month' };
        break;
      case 'dayGridWeek':
        this.nextPreviousButtons = { previous:'Go to previous Week', next: 'Go to next Week' };
        break;
      case 'timeGridDay':
        this.nextPreviousButtons = { previous:'Go to previous Day', next: 'Go to next Day' };
        break;
      default:
        this.nextPreviousButtons = { previous:'Go to previous Month', next: 'Go to next Month' };
    }
    this.getEvents(this.filterParams);
  }

  public calendarNext() {
    this.calendar.getApi().next();
    this.getEvents(this.filterParams);
  }

  public calendarPrev() {
    this.calendar.getApi().prev();
    this.getEvents(this.filterParams);
  }

  public eventsChange(event: MatButtonToggleChange) {
    this.allEventsMode = event.value;
    this.getEvents(this.filterParams);
  }

  public datesRender(event) {
    const calendarView: ViewApi = event.view;
    const startDay = moment(calendarView.activeStart).format(dateFormat.short);
    const endDay = moment(calendarView.activeEnd).format(dateFormat.short);
    this.lastCurrentDates = { startDay, endDay };
    this.calendarTitle = event.view.title;
  }

  public onEventClick(eventData) {
    if (eventData.jsEvent instanceof KeyboardEvent && eventData.jsEvent.key === 'Enter') {
      this.router.navigate(eventData.event.extendedProps.event_source === 'dnt' ? ['/events/dnt/', eventData.event.extendedProps.event.id] : ['/events', eventData.event.extendedProps.event.id]);
    }
    const tempPopupButton = this.popupBtn.nativeElement;
    this.isOnWaitingList = eventData.event.extendedProps.on_waiting_list;
    const eventObj = { ...eventData.event.extendedProps.event };
    eventObj.end_at = eventData.event.end;
    eventObj.start_at = eventData.event.start;
    eventObj.event_source = eventData.event.extendedProps.event_source;
    this.clickedEvent = eventObj;
    tempPopupButton.style.display = '';
    tempPopupButton.style.position = 'absolute';
    tempPopupButton.style.left = `${eventData.jsEvent.pageX}px`;
    tempPopupButton.style.top = `${eventData.jsEvent.pageY}px`;
    this.trigger.openMenu();
  }

  public onPopupClosed() {
    if (this.popupBtn.nativeElement) {
      this.popupBtn.nativeElement.style.display = 'none';
    }
  }

  private getEvents(filterParams: any) {
    this.loading.emit(true);
    this.isLoading = true;
    const filters = Object.assign({}, filterParams);
    if (this.organizationId) {
      filters['organizations[]'] = this.organizationId;
    }
    if (this.allEventsMode) {
      this.eventsService
        .getEventListWithOccurences(this.lastCurrentDates.startDay, this.lastCurrentDates.endDay, filters)
        .subscribe(eventsData => {
          this.events = this.calendarService.mapEventCalendarItems(eventsData.data);
          this.setCalendarOptions();
          this.isLoading = false;
          this.loading.emit(false);
        }
        );
    } else {
      this.usersService
        .getUserAttendedOccurrencesList(this.lastCurrentDates.startDay, this.lastCurrentDates.endDay, filters)
        .subscribe(eventsData => {
          this.events = this.calendarService.mapEventCalendarItems(eventsData.data);
          this.setCalendarOptions();
          this.isLoading = false;
          this.loading.emit(false);
        }
        );
    }
  }

  private setCalendarLocale(locale: string) {
    // No correct day names translation for Nynorsk now
    if (locale === 'nn' || locale === 'no' || locale === 'nb' || locale === 'nb-NO') {
      this.calendarLocale = nbLocale;
    }
  }

  private setCalendarOptions() {
    this.calendarOptions.locale = this.calendarLocale;
    this.calendarOptions.events = this.events;
    if (this.calendar) {
      const api = this.calendar.getApi();
      if (api) {
        api.addEventSource(this.events);
      }
    }
  }
}
