import {InfoType, UserMunicipality} from '@shared/models/user-municipality.model';

export const BremangerMunicipality: UserMunicipality = {
  name: 'bremanger',
  slogan: {
    value: 'Aktiviteter og frivillige oppdrag - nær deg'
  },
  data: {},
  bannerURL: 'https://ucarecdn.com/2ba87b6b-3300-434c-97db-0744555242bb/-/preview/2000x600/',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: true,
  sunset: false,
  hasPayment: true,
  hasTickets: false,
  hasEquipment: false,
  infoSlots: [
    {
      type: InfoType.FRISKUS_LEISURE_ALL,
      title: 'Fritidskonto',
      minAge: 6,
      maxAge: 18,
      amount: 2200,
      url: 'https://bremanger.kommune.no/aktuelt-i-kommunen/fritidskortet-gjer-fritidsaktivitet-tilgjengeleg-for-alle.4606.aspx',
    },
  ]
}
