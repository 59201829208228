import { UserMunicipality } from "@shared/models/user-municipality.model";

export const OksnesMunicipality: UserMunicipality = {
  name: 'oksnes',
  slogan: {value: '', id: 'banner.oksnes', meaning: 'bannerSlogan', description: 'slogan for oksnes'},
  data: {},
  bannerURL: 'https://ucarecdn.com/b37c6b7e-284c-46cd-aa07-1e9ae926f5dc/oksnescoverphoto.png',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
  hasEquipment: false,
  infoPageTitle: 'Fritidskonto',
}
