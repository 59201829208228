import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const BodoMunicipality: UserMunicipality = {
  name: 'bodo',
  slogan: {
    value: 'Aktiviteter og frivillige oppdrag - nær deg', id: 'banner.bodo', meaning: 'bannerSlogan',
    description: 'slogan for Bodø'
  },
  data: {},
  bannerURL: 'https://ucarecdn.com/5d1221e8-de50-44d9-a1a3-a12501ec3493/-/preview/-/quality/smart/-/format/auto/',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: true,
  sunset: false,
  hasPayment: true,
  hasTickets: false,
  hasEquipment: true,
  infoPageTitle: 'Fritidskonto',
  infoSlots: [
    {
      type: InfoType.FRISKUS_LEISURE_CARD,
      title: 'Fritidskortet',
      minAge: 0,
      maxAge: 24,
      url: 'https://bodo.kommune.no/tjenester/kultur-idrett-og-fritid/tilskuddsordninger/fritidskortet/',
    },
    // {
    //   type: InfoType.FRISKUS_LEISURE_ALL,
    //   title: 'Fritidskortet',
    //   minAge: 10,
    //   maxAge: 13,
    //   amount: 1600,
    //   url: 'https://bodo.kommune.no/fritidskortet/',
    // },
    {
      type: InfoType.EQUIPMENT_RENTAL,
      links: [
        {
          title: 'Skattkammeret',
          url: 'https://kirkensbymisjon.no/skattkammeret/bodo/'
        },
        {
          title: 'BUA',
          url: 'https://www.bua.no/bua-bodo/utstyr '
        }
      ]
    },
    {
      type: InfoType.FOR_SENIORS,
      links: [
        {
          title: 'Tusenhjemmet Kultur- og Kunnskapssenter',
          url: ['/organisations', '187f3447-74d9-4f6c-bb24-771f3b619335']
        },
        {title: 'Pensjonistrevyen', url: ['/organisations', '5abef25d-579f-4d68-af80-521db10d66d5']},
      ]
    },
    {
      type: InfoType.VENUE,
      title: "Idrettsanlegg og lokaler",
      url: "https://bodo.kommune.no/tjenester/kultur-idrett-og-fritid/idrett/leie-av-idrettsanlegg/",
    },
    {
      type: InfoType.VOLUNTEER_CENTRE,
      url: ['/organisations', '454d973e-49b3-4bec-828a-5993a032fcce']
    },
    {
      type: InfoType.VOLUNTEER_WORK,
      url: 'https://bodo.kommune.no/tjenester/helse-og-mestring/friskliv-dagtilbud-og-aktivitet/frivillighet-og-medvirkning/vil-du-bli-frivillig/'
    },
    {
      type: InfoType.FUNDING, url: 'https://bodo.kommune.no/tjenester/kultur-idrett-og-fritid/tilskuddsordninger/tilskudd-til-fysisk-aktivitet-og-private-anlegg/'
    },
  ]
}
