import { Injectable } from '@angular/core';
import { GenericHttpService } from '@services/generic-http/generic-http.service';
import { API_URL_KEY, API_URL_MAP } from '@config/api-url.config';
import { Observable } from 'rxjs';
import { MUNICIPALITY_CONFIG } from '@config/municipality.config';
import { UserMunicipality } from '@shared/models/user-municipality.model';
import { shareReplay } from 'rxjs/operators';
import { Municipality } from '@shared/models/municipality.model';

@Injectable({
  providedIn: 'root'
})
export class MunicipalitiesService {
  private municipalitiesRes$: Observable<{ data: Municipality[] }>;

  constructor(
    private http: GenericHttpService
  ) {}

  public get municipalities() {
    if (!this.municipalitiesRes$) {
      this.municipalitiesRes$ = this.getMunicipalities().pipe(
        shareReplay()
      );
    }

    return this.municipalitiesRes$;
  }

  public getMunicipalities(): Observable<{ data: Municipality[] }> {
    const url = `${API_URL_MAP.municipalities.base}`;
    return this.http.get(API_URL_KEY.event, url);
  }

  public getUserLocation() {
    const url = `${API_URL_MAP.users.base}${API_URL_MAP.users.me}${API_URL_MAP.users.geoip}`;
    return this.http.get(API_URL_KEY.user, url);
  }

  public getUserMunicipalityByIP(cityFromIP) {
    const contractedMunicipalityName: string | undefined = Object.keys(MUNICIPALITY_CONFIG).find(municipalityName => {
      return cityFromIP.toLowerCase() === municipalityName.toLowerCase();
    });
    return contractedMunicipalityName ? MUNICIPALITY_CONFIG[contractedMunicipalityName] : MUNICIPALITY_CONFIG.default;
  }

  public getUserMunicipality(): UserMunicipality {
    const hostname = window.location.hostname;
    const municipalityFromHostName = hostname.split('.')[0];
    const contractedMunicipalityName: string | undefined = Object.keys(MUNICIPALITY_CONFIG).find(municipalityName => {
      return municipalityFromHostName === municipalityName;
    });

    return contractedMunicipalityName ? MUNICIPALITY_CONFIG[contractedMunicipalityName] : MUNICIPALITY_CONFIG.default;
  }

  getMunicipalityDomain(municipality: Municipality) {
    const host = this.splitHostname()
      .slice(-2).join('.');
    return `https://${municipality.key}.${host}`;
  }

  splitHostname(): string[] {
    return window.location
      .hostname
      .split('.');
  }

  isOnMunicipalityDomain() {
    const hostname = this.splitHostname();
    // Remove www for correct check
    if (hostname[0] === 'www') {
      hostname.shift();
    }

    return hostname.length > 2;
  }

  public get isLeisureCardEnabled(): boolean {
    return this.getUserMunicipality().hasLeisureCard;
  }

  public get canUserClaimLeisureCard(): boolean {
    return this.getUserMunicipality().userCanClaimLeisureCard;
  }

  public get isEquipmentTicketEnabled(): boolean {
    return this.getUserMunicipality().hasEquipment;
  }

  public get isTicketEnabled(): boolean {
    return this.getUserMunicipality().hasTickets;
  }

  public get isSunset(): boolean {
    return this.getUserMunicipality().sunset;
  }

  public getMunicipalityAdmins(municipalityId: string, page: number = 1, q?: string) {
    const searchParams: any = {};
    if (q) {
      searchParams.q = q;
    }
    searchParams.page = page;
    const url = `${API_URL_MAP.municipalities.base}/${municipalityId}/admins`;

    return this.http.get(API_URL_KEY.event, url, searchParams);
  }

  public getMunicipalityCards(municipalityId: string, page: number = 1, filterObj?: any) {
    const searchParams: any = {};
    searchParams.page = page;
    if (filterObj && filterObj.q) {
      searchParams.q = filterObj.q;
    }
    const url = `${API_URL_MAP.municipalities.base}/${municipalityId}/leisure-cards`;

    return this.http.get(API_URL_KEY.card, url, searchParams);
  }

  public searchTicketsByPrefix(municipalityId: string, page: number = 1, filterObj?: any) {
    const searchParams: any = {page};

    if (filterObj) {
      if (filterObj.q) {
        searchParams.q = filterObj.q;
      }
      if (filterObj.start_date) {
        searchParams.start_date = filterObj.start_date;
      }
      if (filterObj.end_date) {
        searchParams.end_date = filterObj.end_date;
      }
    }
    const url = `${API_URL_MAP.municipalities.base}/${municipalityId}/tickets`;

    return this.http.get(API_URL_KEY.card, url, searchParams);
  }

  public getMunicipalityCardsTransactions(municipalityId: string, page: number = 1, filterObj?: object) {
    const searchParams: any = {...filterObj};
    searchParams.page = page;
    const url = `${API_URL_MAP.municipalities.base}/${municipalityId}/transactions`;

    return this.http.get(API_URL_KEY.card, url, searchParams);
  }

  public getMuniciplaityCardsStatistic(municipalityId: string, filterParams?: object) {
    const params = {...filterParams};
    const url = `${API_URL_MAP.municipalities.base}/${municipalityId}/leisure-card-statistics`;

    return this.http.get(API_URL_KEY.card, url, params);
  }

  public getMunicipalityOrganizationsStatistics(municipalityId: string, filterParams?: object) {
    const params = {...filterParams};
    const url = `${API_URL_MAP.municipalities.base}/${municipalityId}/organization-statistics`;

    return this.http.get(API_URL_KEY.group, url, params);
  }

  public exportMunicipalityCardsTransactions(municipalityId: string, filterParams?: object) {
    const params = {...filterParams};
    const url = `${API_URL_MAP.municipalities.base}/${municipalityId}/export-transactions`;
    return this.http.getFile(API_URL_KEY.card, url, params);
  }

  public exportMunicipalityPendingTransactions(municipalityId: string, filterParams?: object) {
    const params = {...filterParams};
    const url = `${API_URL_MAP.municipalities.base}/${municipalityId}/export-pending`;
    return this.http.getFile(API_URL_KEY.card, url, params);
  }

  public exportMunicipalityCards(municipalityId: string, filterParams?: object) {
    const params = {...filterParams};
    const url = `${API_URL_MAP.municipalities.base}/${municipalityId}/export-leisure-cards`;
    return this.http.getFile(API_URL_KEY.card, url, params);
  }
  public exportTickets(municipalityId: string, filterParams?: object) {
    const params = {...filterParams};
    const url = `${API_URL_MAP.municipalities.base}/${municipalityId}/export-tickets`;
    return this.http.getFile(API_URL_KEY.card, url, params);
  }

  public exportCardsForAttachments() {
    const url = `${API_URL_MAP.card.base}/attachments/export`;
    return this.http.getFile(API_URL_KEY.card, url);
  }

}
