import {InfoType, UserMunicipality} from "@shared/models/user-municipality.model";

export const StavangerDevMunicipality: UserMunicipality = {
  name: 'stavanger-dev',
  slogan: {
    value: 'Aktiviteter og frivillige oppdrag - nær deg',
    id: 'banner.stavanger',
    meaning: 'bannerSlogan',
    description: 'slogan for stavanger'
  },
  data: {},
  bannerURL: 'https://ucarecdn.com/581effb7-f407-48c5-b2e1-f945a6fef528/forsidebildestavanger.jpg',
  bannerType: 'image',
  hasLeisureCard: true,
  userCanClaimLeisureCard: true,
  canClaimInOrganization: true,
  hasSubsidiaryOrg: true,
  sunset: false,
  hasPayment: true,
  hasTickets: false,
  hasEquipment: false,
  infoSlots: [
    {
      type: InfoType.FRISKUS_LEISURE_ALL,
      title: 'Fritidskonto',
      minAge: 6,
      maxAge: 18,
      amount: 1000,
    },
    {
      type: InfoType.FUNDING, url: 'https://www.stavanger.kommune.no/kultur-og-fritid/frivillighet-i-Stavanger/tilskuddsordninger/'
    },
    {
      type: InfoType.VOLUNTEER_WORK,
      url: 'https://www.stavanger.kommune.no/kultur-og-fritid/frivillighet-i-Stavanger/'
    },
    {
      type: InfoType.VENUE,
      url: 'https://site1.aktiv-kommune.no/stavanger/bookingfrontend/',
    },
  ]
}
