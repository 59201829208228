import {
  Component,
  OnInit,
  ViewChild,
  AfterViewChecked,
  Input,
  SimpleChanges,
  OnChanges,
  ElementRef,
  Output,
  EventEmitter,
  Inject,
  LOCALE_ID
} from '@angular/core';
import * as moment from 'moment';
import { CalendarOptions, FullCalendarComponent, LocaleInput, ViewApi } from '@fullcalendar/angular';
import { EventsService } from '@services/remote-api/events.service';
import { UsersService } from '@services/remote-api/users.service';
import { EventCalendarService } from '../event-calendar.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { dateFormat } from '@config/index';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin, { DayGridView } from '@fullcalendar/daygrid';
import { AuthService } from '@features/auth/shared/services/auth/auth.service';
import nnLocale from '@fullcalendar/core/locales/nn';
import nbLocale from '@fullcalendar/core/locales/nb';

@Component({
  selector: 'friskus-mobile-calendar',
  templateUrl: './mobile-calendar.component.html',
  styleUrls: ['./mobile-calendar.component.scss']
})
export class MobileCalendarComponent implements OnInit, AfterViewChecked, OnChanges {
  @Input() filterParams = {};
  @Input() organizationId: string;
  @Input() isTimePlan: boolean;
  @Output() loading: EventEmitter<any> = new EventEmitter();
  @ViewChild('mobileCalendar') mobileCalendar: FullCalendarComponent;
  @ViewChild('occurrencesList') occurrenceListRef: ElementRef;
  public occurrences: any[] = [];
  public calendarEvents: any[] = [];
  public calendarLocale: LocaleInput;
  public timeFormat = {};
  private lastCurrentDates = {
    startDay: moment().startOf('month').format(dateFormat.short),
    endDay: moment().endOf('month').format(dateFormat.short)
  };
  public calendarTitle;
  public isAllEventsView = true;
  public choosedDate: string;
  public user: any;
  public calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    plugins: [interactionPlugin, dayGridPlugin],
    dayHeaders: true,
    events: this.calendarEvents,
    eventTimeFormat: this.timeFormat,
    slotLabelFormat: this.timeFormat,
    selectable: false,
    fixedWeekCount: false,
    showNonCurrentDates: false,
    displayEventEnd: true,
    firstDay: 1,
    // eventLimit: 1,
    eventMaxStack: 0,
    dayMaxEventRows: 0,
    timeZone: 'local',
    dateClick: this.dateClick.bind(this),
    viewDidMount: this.datesRender.bind(this),
    datesSet: this.datesRender.bind(this),
    moreLinkContent: this.moreLinkContent.bind(this),
  };

  constructor(
    private eventsService: EventsService,
    private usersService: UsersService,
    private calendarService: EventCalendarService,
    private authService: AuthService,
    @Inject(LOCALE_ID) public locale: string
  ) {
      this.calendarOptions.eventTimeFormat = this.calendarService.timeFormat;
      this.calendarOptions.slotLabelFormat = this.calendarService.timeFormat;
  }

  ngOnInit() {
    this.authService.authState.subscribe(user => this.user = user);
    this.setCalendarLocale(this.locale);
  }

  ngAfterViewChecked() {
    this.calendarOptions.selectable = true;
    // this.mobileCalendar.selectable = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.lastCurrentDates.startDay && this.lastCurrentDates.endDay) {
      if (this.isTimePlan) {
        this.isAllEventsView = false;
      } else {
        this.choosedDate = null;
      }

      this.getEvents(this.filterParams);
    }
  }

  public calendarNext() {
    this.mobileCalendar.getApi().next();
    this.getEvents(this.filterParams);
  }

  public calendarPrev() {
    this.mobileCalendar.getApi().prev();
    this.getEvents(this.filterParams);
  }

  public eventsChange(event: MatButtonToggleChange) {
    this.isAllEventsView = event.value;
    this.choosedDate = null;
    this.getEvents(this.filterParams);
  }

  public dateClick(event) {
    this.choosedDate = moment(event.date).format('dddd, MMMM DD');
    const startDay = moment(event.date).format(dateFormat.short);
    const endDay = moment(event.date).add(1, 'day').format(dateFormat.short);
    const currentDates = { startDay, endDay: startDay };
    this.getOccurrences(currentDates);
  }

  public datesRender(event) {
    const calendarView: ViewApi = event.view;
    const startDay = moment(calendarView.activeStart).format(dateFormat.short);
    const endDay = moment(calendarView.activeEnd).format(dateFormat.short);
    this.lastCurrentDates = { startDay, endDay };
    this.calendarTitle = event.view.title;
  }

  private getEvents(filterParams: any) {
    const filters = Object.assign({}, filterParams);
    if (this.organizationId) {
      filters['organizations[]'] = this.organizationId;
    }
    this.loading.emit(true);
    if (this.isAllEventsView) {
      this.eventsService
        .getEventListWithOccurences(this.lastCurrentDates.startDay, this.lastCurrentDates.endDay, filters)
        .subscribe(eventsData => {
          this.loading.emit(false);
          this.occurrences = eventsData.data;
          this.calendarEvents = this.calendarService.mapEventCalendarItems(eventsData.data);
          this.setCalendarOptions();
        }
        );
    } else {
      this.usersService
        .getUserAttendedOccurrencesList(this.lastCurrentDates.startDay, this.lastCurrentDates.endDay, filters)
        .subscribe(eventsData => {
          this.loading.emit(false);
          this.occurrences = eventsData.data;
          this.calendarEvents = this.calendarService.mapEventCalendarItems(eventsData.data);
          this.setCalendarOptions();
        }
      );
    }
  }

  private getOccurrences(currentDates) {
    const filters = Object.assign({}, this.filterParams);
    if (this.organizationId) {
      filters['organizations[]'] = this.organizationId;
    }
    this.loading.emit(true);
    if (this.isAllEventsView) {
      this.eventsService
        .getEventListWithOccurences(currentDates.startDay, currentDates.endDay, filters)
        .subscribe(eventsData => {
          this.loading.emit(false);
          this.occurrences = eventsData.data;
          this.scrollToOccurrencesList(this.occurrences.length);
        }
        );
    } else {
      this.usersService
        .getUserAttendedOccurrencesList(currentDates.startDay, currentDates.endDay, filters)
        .subscribe(eventsData => {
          this.loading.emit(false);
          this.occurrences = eventsData.data;
          this.scrollToOccurrencesList(this.occurrences.length);
        }
        );
    }
  }

  private scrollToOccurrencesList(occurenceListLength) {
    if (occurenceListLength && this.occurrenceListRef) {
      this.occurrenceListRef.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  private setCalendarLocale(locale: string) {
    if (locale === 'nn') {
      this.calendarLocale = nnLocale;
    }

    if (locale === 'no' || locale === 'nb') {
      this.calendarLocale = nbLocale;
    }
  }

  private setCalendarOptions() {
    this.calendarOptions.locale = this.calendarLocale;
    this.calendarOptions.events = this.calendarEvents;
    if (this.mobileCalendar) {
      const api = this.mobileCalendar.getApi();
      if (api) {
        api.addEventSource(this.calendarEvents);
      }
    }
  }

  private moreLinkContent(arg: {num: number}) {
    const eventLabel = arg.num === 1 ?
      $localize `:event label|@@label.numberEvent:event` :
      $localize `:events label|@@label.numberEvents:events`;
    return { html: `${arg.num} ${eventLabel}` };
  }

}
